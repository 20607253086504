// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio-container {
  margin-left: 35px;
  margin-top: 10px;
  cursor: pointer;
}

.custom-radio::after {
  content: "";
  display: block;
  /* border: 1px solid #707070; */
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transform: translateX(-35px);
  margin-top: 2px;
  transition: all 0.2s ease-in;
}

.custom-radio:checked::after {
  background-color: #c0d349;
  border-color: #c0d349;
  border: 1px solid #707070;

}

.custom-radio {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/inputs/inputs.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,+BAA+B;EAC/B,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,4BAA4B;EAC5B,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,yBAAyB;;AAE3B;;AAEA;EACE,yBAAyB;EAGzB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,SAAS;EACT,QAAQ;AACV","sourcesContent":[".custom-radio-container {\n  margin-left: 35px;\n  margin-top: 10px;\n  cursor: pointer;\n}\n\n.custom-radio::after {\n  content: \"\";\n  display: block;\n  /* border: 1px solid #707070; */\n  background-color: #fff;\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n  transform: translateX(-35px);\n  margin-top: 2px;\n  transition: all 0.2s ease-in;\n}\n\n.custom-radio:checked::after {\n  background-color: #c0d349;\n  border-color: #c0d349;\n  border: 1px solid #707070;\n\n}\n\n.custom-radio {\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  position: absolute;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
