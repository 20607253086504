import { Template } from "components/modecules";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import form from "utils/form";
import Questions from "./_components/questions";
import Register from "./_components/register";
import Welcome from "./_components/welcome";

export default function QuizzPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const endQuiz = (event) => {
  //   event.preventDefault();
  //   localStorage.setItem("participation", JSON.stringify(true));
  //   event.target.submit();
  // };

  // redirection page sucess
  const endQuiz = async (event) => {
    event.preventDefault();

    localStorage.setItem("participation", JSON.stringify(true));

    const formData = new FormData(event.target);

    try {
      await fetch(form.url, {
        method: form.method,
        body: formData,
        mode: "no-cors",
      });

      setIsSubmitted(true);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
      alert("Une erreur s'est produite lors de la soumission.");
    }
  };

  const getStep = (section) => {
    if (section === 0) {
      return (
        <Template backgroundColor="bg-white" dark>
          <Welcome onNext={() => setCurrentStep(1)} />
        </Template>
      );
    }

    if (section >= 1) {
      return (
        <Template>
          <Register onNext={() => setCurrentStep(2)} show={currentStep === 1} />
          <Questions show={currentStep === 2} />
        </Template>
      );
    }
  };

  return (
    <form method={form.method} action={form.url} onSubmit={endQuiz}>
      {getStep(currentStep)}
    </form>
  );
}
