import { Card, SecondaryButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Register({ onNext = () => {}, show = false }) {
  const [isAwnserd, setIsAwnserd] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    telephone: "",
  });

  const [touched, setTouched] = useState({
    email: false,
    telephone: false,
  });

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    home_site: "",
    rules: false,
  });

  const entries = {
    lastname: "entry.349956804",
    firstname: "entry.338357105",
    email: "entry.2088730206",
    telephone: "entry.1664550765",
    home_site: "entry.1095275552",
  };

  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };
  const handleEmailChange = (event) => {
    setUser({ ...user, email: event.target.value });
    setTouched((prev) => ({ ...prev, email: true }));
  };

  const handleTelephoneChange = (event) => {
    setUser({ ...user, telephone: event.target.value });
    setTouched((prev) => ({ ...prev, telephone: true }));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "L'email n'est pas valide.",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
      return true;
    }
  };

  const validateTelephone = (telephone) => {
    const phonePattern = /^(?:\+33|0)[1-9](?:\s?\d{2}){4}$/;
    if (!phonePattern.test(telephone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        telephone: "Le numéro de téléphone n'est pas valide.",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        telephone: "",
      }));
      return true;
    }
  };

  useEffect(() => {
    const emailValid = validateEmail(user.email);
    const phoneValid = validateTelephone(user.telephone);
    if (
      user.firstname.length >= 2 &&
      user.lastname.length >= 2 &&
      emailValid &&
      phoneValid &&
      user.home_site.length >= 2 &&
      user.rules
    ) {
      setIsAwnserd(true);
    } else {
      setIsAwnserd(false);
    }
  }, [user]);

  return (
    <div
      className={joinClasses(
        "flex justify-between w-full",
        show ? "block" : "hidden"
      )}
    >
      <div
        className="w-full bg-no-repeat bg-center"
        style={{
          backgroundImage: `url("/assets/icons/welcome2-left.svg")`,
        }}
      />
      <Card className="mt-16 z-10 relative">
        <h2 className="font-raleway-black text-left text-[25px] text-fourth uppercase mt-8 mb-5">
          Informations personnelles
        </h2>
        <div className="flex flex-col md:flex-row md:gap-5">
          <input
            type="text"
            name={entries.lastname}
            onChange={(event) =>
              setUser({ ...user, lastname: event.target.value })
            }
            placeholder="Nom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
            required
          />
          <input
            type="text"
            name={entries.firstname}
            onChange={(event) =>
              setUser({ ...user, firstname: event.target.value })
            }
            placeholder="Prénom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
            required
          />
        </div>
        <div className="flex flex-col md:flex-row md:gap-5">
          <div className="w-full mb-5">
            <input
              type="email"
              name={entries.email}
              onChange={handleEmailChange}
              placeholder="E-mail"
              className="w-full border border-gray-300 rounded-md p-3 focus:outline-primary focus:border-primary"
              required
            />
            {touched.email && errors.email && (
              <p className="text-red-800 font-semibold text-[12px]">
                {errors.email}
              </p>
            )}
          </div>
          <div className="w-full mb-5">
            <input
              type="tel"
              name={entries.telephone}
              onChange={handleTelephoneChange}
              placeholder="Téléphone"
              className="w-full border border-gray-300 rounded-md p-3 focus:outline-primary focus:border-primary"
              required
            />
            {touched.telephone && errors.telephone && (
              <p className="text-red-800 font-semibold text-[12px]">
                {errors.telephone}
              </p>
            )}
          </div>
        </div>
        <div className="w-full mb-5">
          <input
            type="tel"
            name={entries.home_site}
            onChange={(event) =>
              setUser({ ...user, home_site: event.target.value })
            }
            placeholder="Site de rattachement"
            defaultValue=""
            className="w-full border border-gray-300 rounded-md p-3 focus:outline-primary focus:border-primary"
            required
          />
        </div>

        <div>
          <input
            type="checkbox"
            name="cgu"
            id="cgu"
            className="mr-2"
            onChange={(event) =>
              setUser({ ...user, rules: event.target.checked })
            }
            required
          />
          <label htmlFor="cgu">
            J'accepte le{" "}
            <Link to="/reglement" className="text-fourth">
              règlement du jeu concours
            </Link>
          </label>
        </div>
        <div className="flex justify-center mt-16">
          {isAwnserd && (
            <SecondaryButton
              onClick={handleClick}
              className={joinClasses(
                !isAwnserd && "!cursor-not-allowed pointer-events-none",
                "!bg-fourth"
              )}
            >
              Continuer {">"}
            </SecondaryButton>
          )}
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-center"
        style={{
          backgroundImage: `url("/assets/icons/welcome2-right.svg")`,
        }}
      />
    </div>
  );
}
