import { Card, SubTitle } from "components/atoms";
import { Template } from "components/modecules";

export default function SuccessPage() {
  return (
    <Template>
      <Card className="m-auto flex flex-col justify-center mt-10">
        <h2 className="font-raleway-black text-center text-[25px] text-fourth uppercase mt-8 mb-5">
          Votre réponse a été enregistrée.
        </h2>
        <p className="text-center mt-5">
          Merci d’avoir participé ! Les meilleurs seront tirés au sort pour
          remporter des lots.
        </p>
      </Card>
    </Template>
  );
}
