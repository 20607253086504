import { Card, PrimaryButton, Title } from "components/atoms";

export default function Welcome({ onNext = () => {} }) {
  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <div className="flex justify-between w-full w-full">
      <div
        className="w-full bg-no-repeat bg-center "
        style={{
          backgroundImage: `url("/assets/icons/welcome2-left.svg")`,
        }}
      />
      <Card className="mt-16 z-10 flex flex-col justify-around">
        {/* TITLE BLOCK */}
        <div className="relative w-fit mx-auto">
          <Title className="text-center z-10 border-b border-black border-dotted uppercase text-transparent text-stroke text-stroke-black-500">
            Quiz
          </Title>
        </div>

        <h2 className="font-proximanova-extrabold text-center text-[25px] text-fourth uppercase mt-8 mb-5">
          Les maladies invalidantes
        </h2>

        <p className="text-black text-center font-proximanova-regular text-[20px] leading-[24px]">
          Les maladies invalidantes touchent des millions de personnes et
          impactent profondément leur quotidien.
          <br />
          <br />
          Mieux les comprendre, c'est faire un pas vers un environnement
          inclusif et bienveillant. Alors tester vos connaissances tout en
          découvrant pour faire de vous un allié pour ceux qui en ont besoin !
          💼✨
        </p>
        <br />
        <p className="text-black text-center font-proximanova-regular text-[20px] leading-[24px]">
          Alors, prêt(e) à relever le défi ? 🚀 <br />
          Testez-vous et faites la différence !
        </p>
        <div className="flex justify-center mt-10">
          <PrimaryButton onClick={handleClick}>Commencer</PrimaryButton>
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-right-bottom"
        style={{
          backgroundImage: `url("/assets/icons/welcome2-right.svg")`,
        }}
      />
    </div>
  );
}
