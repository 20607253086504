const questions = [
  {
    id: 1,
    name: "entry.2142548378",
    type: "checkbox",
    title:
      "Parmi les pathologies ci-dessous, lesquelles sont des maladies invalidantes ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Endométriose",
        value: "Endométriose",
      },
      {
        label: "Diabète",
        value: "Diabète",
      },
      {
        label: "Hypertension",
        value: "Hypertension",
      },
      {
        label: "Cancer",
        value: "Cancer",
      },
    ],
    rightAnswers: [
      {
        answer: "Endométriose",
      },
      {
        answer: "Diabète",
      },
      {
        answer: "Hypertension",
      },
      {
        answer: "Cancer",
      },
    ],
    description:
      "💡 À cause de leurs effets sur la vie quotidienne, toutes ces maladies sont considérées comme des maladies invalidantes et peuvent être reconnues comme un handicap au travail.",
  },
  {
    id: 2,
    name: "entry.651238804",
    type: "radio",
    title:
      "Pourquoi la fibromyalgie est-elle souvent méconnue comme maladie invalidante sur le lieu de travail ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Parce qu'elle affecte uniquement les personnes âgées.",
        value: "Parce qu'elle affecte uniquement les personnes âgées.",
      },
      {
        label:
          "Parce que les douleurs et la fatigue sont invisibles et peuvent varier d'un jour à l'autre.",
        value:
          "Parce que les douleurs et la fatigue sont invisibles et peuvent varier d'un jour à l'autre.",
      },
      {
        label:
          "Parce que ses symptômes disparaissent avec un simple traitement médicamenteux.",
        value:
          "Parce que ses symptômes disparaissent avec un simple traitement médicamenteux.",
      },
      {
        label: "Parce qu'elle ne touche que les muscles des jambes.",
        value: "Parce qu'elle ne touche que les muscles des jambes.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Parce que les douleurs et la fatigue sont invisibles et peuvent varier d'un jour à l'autre.",
      },
    ],
    description:
      "💡 La fibromyalgie est souvent compliquée à diagnostiquer et à reconnaître parce que les symptômes, même s'ils sont réels, ne se voient pas toujours. Cela peut rendre difficile l'obtention d'adaptations au travail, même si les personnes qui en souffrent en souffrent vraiment.",
  },
  {
    id: 3,
    name: "entry.665879711",
    type: "checkbox",
    title:
      "Pourquoi la fatigue chronique est-elle un symptôme commun dans plusieurs maladies invalidantes ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "La maladie entraine toujours des problèmes de sommeil.",
        value: "La maladie entraine toujours des problèmes de sommeil.",
      },
      {
        label:
          "Elle résulte de l'inflammation ou du stress oxydatif dans le corps.",
        value:
          "Elle résulte de l'inflammation ou du stress oxydatif dans le corps.",
      },
      {
        label: "Elle peut résulter du traitement médicamenteux.",
        value: "Elle peut résulter du traitement médicamenteux.",
      },
      {
        label: "Elle est généralement facile à traiter avec des médicaments.",
        value: "Elle est généralement facile à traiter avec des médicaments.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Elle résulte de l'inflammation ou du stress oxydatif dans le corps.",
      },
      {
        answer: "Elle peut résulter du traitement médicamenteux.",
      },
    ],
    description:
      "La fatigue chronique est un symptôme courant de plusieurs maladies invalidantes, comme la sclérose en plaques, la fibromyalgie et l'endométriose. Elle peut avoir différentes causes liées à l'inflammation et au fonctionnement du corps.\n\n💡 Cela rend souvent difficile de gérer la charge de travail et de participer aux activités quotidiennes.",
  },
  {
    id: 4,
    name: "entry.1446828048",
    type: "checkbox",
    title:
      "Quelles pathologies peuvent entraîner des variations d'énergie à cause de l'imprévisibilité des symptômes ?",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Diabète de type 2",
        value: "Diabète de type 2",
      },
      {
        label: "Polyarthrite rhumatoïde",
        value: "Polyarthrite rhumatoïde",
      },
      {
        label: "Syndrome de l'intestin irritable",
        value: "Syndrome de l'intestin irritable",
      },
      {
        label: "Les migraines chroniques",
        value: "Les migraines chroniques",
      },
    ],
    rightAnswers: [
      {
        answer: "Polyarthrite rhumatoïde",
      },
      {
        answer: "Les migraines chroniques",
      },
    ],
    description:
      "💡 Ces deux maladies auto-immunes provoquent des symptômes imprévisibles et graves (comme des douleurs et de la fatigue), qui peuvent affecter l'énergie.",
  },
  {
    id: 5,
    name: "entry.827175917",
    type: "radio",
    title: "Une maladie invalidante ponctuelle est-elle un handicap ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [
      {
        answer: "Oui",
      },
    ],
    description:
      "💡 Une situation de santé, même ponctuelle, peut être reconnue comme un handicap au travail dès que la situation est durable (+ d'1 an). C'est le cas pour les cancers par exemple.",
  },
  {
    id: 6,
    name: "entry.997651519",
    type: "radio",
    title:
      "Pourquoi la reconnaissance des maladies invalidantes au travail est-elle essentielle ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Pour obtenir des congés payés supplémentaires",
        value: "Pour obtenir des congés payés supplémentaires",
      },
      {
        label:
          "Pour bénéficier de conditions de travail adaptées et éviter la discrimination",
        value:
          "Pour bénéficier de conditions de travail adaptées et éviter la discrimination",
      },
      {
        label: "Pour garantir une rémunération plus élevée",
        value: "Pour garantir une rémunération plus élevée",
      },
      {
        label: "Pour limiter les horaires de travail à 20 heures par semaine",
        value: "Pour limiter les horaires de travail à 20 heures par semaine",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Pour bénéficier de conditions de travail adaptées et éviter la discrimination",
      },
    ],
    description:
      "💡 La reconnaissance du statut de travailleur handicapé aide les employés à obtenir des adaptations pour leur travail. Cela évite que leur handicap les empêche de bien faire leur travail et les protège des discriminations.",
  },
  {
    id: 7,
    name: "entry.766732835",
    type: "radio",
    title:
      "Une personne atteinte d'apnée du sommeil, provoquant une fatigue diurne sévère, peut-elle être reconnue comme ayant un handicap au travail ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [
      {
        answer: "Oui",
      },
    ],
    description:
      "💡 L'apnée du sommeil, si elle n'est pas soignée, peut causer une fatigue constante, des problèmes de concentration et augmenter le risque d'accidents au travail. Si cela affecte longtemps le travail, cela peut être considéré comme un handicap.",
  },
  {
    id: 8,
    name: "entry.124931880",
    type: "radio",
    title:
      "Une personne souffrant d'insuffisance cardiaque, avec des limitations d'efforts physiques et des périodes de fatigue importante, peut-elle demander une reconnaissance de handicap ?",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [
      {
        answer: "Oui",
      },
    ],
    description:
      "L'insuffisance cardiaque rend plus difficile de faire des efforts physiques et peut provoquer de la fatigue, des essoufflements et des douleurs.\nCes problèmes peuvent rendre certaines tâches compliquées ou impossibles et demander des adaptations au travail, comme limiter les déplacements ou les efforts.",
  },
  {
    id: 9,
    name: "entry.893892758",
    type: "radio",
    title:
      "Une personne souffrant d'une maladie de Lyme chronique, entraînant des douleurs articulaires et une fatigue persistante, peut-il être reconnu comme ayant un handicap au travail ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [
      {
        answer: "Oui",
      },
    ],
    description:
      "La maladie de Lyme peut causer des douleurs aux articulations, des problèmes de mémoire, de concentration et une grande fatigue. Si ces symptômes rendent le travail difficile, des adaptations peuvent être nécessaires, comme réduire les horaires de travail ou travailler à domicile.",
  },
  {
    id: 10,
    name: "entry.12903041",
    type: "checkbox",
    title:
      "Quels types d'aménagements peuvent être mis en place pour un salarié souffrant de douleurs chroniques ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Adapter le poste de travail avec un fauteuil ergonomique",
        value: "Adapter le poste de travail avec un fauteuil ergonomique",
      },
      {
        label: "Réduire la charge de travail",
        value: "Réduire la charge de travail",
      },
      {
        label: "Mettre en place des pauses régulières",
        value: "Mettre en place des pauses régulières",
      },
      {
        label: "Augmenter son salaire",
        value: "Augmenter son salaire",
      },
    ],
    rightAnswers: [
      {
        answer: "Adapter le poste de travail avec un fauteuil ergonomique",
      },
      {
        answer: "Réduire la charge de travail",
      },
      {
        answer: "Mettre en place des pauses régulières",
      },
    ],
    description:
      "Les adaptations pour un salarié avec des douleurs chroniques peuvent inclure du matériel adapté, moins de tâches physiques et plus de pauses pour diminuer les douleurs causées par les mouvements répétés ou longs. Augmenter le salaire ne réduira pas les douleurs liées à sa santé.",
  },
];

export default questions;
