import joinClasses from "helpers/joinClasses";

export function Checkbox({
  className = "",
  onClick = () => {},
  label = "",
  ...props
}) {
  return (
    <label className="block custom-radio-container font-proximanova-regular text-[20px] leading-[25px]">
      <input
        className={joinClasses("custom-radio", className)}
        type="checkbox"
        onClick={onClick}
        {...props}
      />
      {label}
    </label>
  );
}
