import {
  Checkbox,
  PrimaryButton,
  Radio,
  SecondaryButton,
  Textarea,
} from "components/atoms";

import { QuestionTemplate } from "components/modecules";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import questions from "utils/questions";
import React from "react";

export default function Questions({ show = false }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAwnserd, setIsAwnserd] = useState(false);
  const [isAnswerDisplayed, setIsAnswerDisplayed] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    setIsAwnserd(false);
    setIsAnswerDisplayed(true);
    setIsFormSubmitted(false);
    window.scrollTo(0, 0);
  };

  const handleAwnser = (event) => {
    setIsAwnserd(true);
    window.scrollTo(0, document.body.scrollHeight);
    const value = event.target.value;
  };

  // pour afficher réponse + explication
  const handleClickAnswer = (event) => {
    event.preventDefault();
    setIsAnswerDisplayed(false);
    setIsFormSubmitted(true);
  };

  const getDescription = (type) => {
    switch (type) {
      case "checkbox":
        return "Plusieurs réponses possibles";
      case "radio":
        return "Une seule réponse possible";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (questions[currentQuestion].bonus) {
      setIsAwnserd(true);
    }
  }, [currentQuestion]);

  return (
    <div className={show ? "block" : "hidden"}>
      {questions.map((question, index) => (
        <QuestionTemplate
          question={question}
          active={index === currentQuestion}
          position={currentQuestion + 1}
          key={question.id}
        >
          <p className="mt-10 font-proximanova-regular text-[14px] text-fourth mb-5">
            {getDescription(question.type)}
          </p>

          <div>
            {question.answers ? (
              question.answers?.map((answer, index) => {
                const answerKey = `${question.name}-${answer.value}`;
                switch (question.type) {
                  case "checkbox":
                    return (
                      <div
                        key={answerKey}
                        className={joinClasses(
                          isFormSubmitted
                            ? question.rightAnswers.some(
                                (rightAnswer) =>
                                  rightAnswer.answer === answer.label ||
                                  rightAnswer.answer === answer.value
                              )
                              ? "bg-[#c0d34989] pointer-events-none"
                              : "pointer-events-none" // bg-red-200
                            : "",
                          "p-1 pb-2 m-1 rounded-xl"
                        )}
                      >
                        <Checkbox
                          type={question.type}
                          name={question.name}
                          label={answer.label}
                          value={answer.value}
                          onChange={handleAwnser}
                          key={question.name + index}
                        />
                      </div>
                    );

                  case "radio":
                    return (
                      <div
                        key={answerKey}
                        className={joinClasses(
                          isFormSubmitted
                            ? question.rightAnswers.some(
                                (rightAnswer) =>
                                  rightAnswer.answer === answer.label
                              )
                              ? "bg-[#c0d34989] pointer-events-none"
                              : "pointer-events-none" // bg-red-200
                            : "",
                          "p-1 pb-2 m-1 rounded-xl"
                        )}
                      >
                        <Radio
                          type={question.type}
                          name={question.name}
                          label={answer.label}
                          value={answer.value}
                          onChange={handleAwnser}
                          key={question.name + index}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })
            ) : (
              <Textarea
                type={question.type}
                name={question.name}
                key={question.name + index}
                placeholder="Écrivez votre réponse ici"
              />
            )}
          </div>

          {/* afficher l'explication */}

          {!isAnswerDisplayed && (
            <div className="mt-4 font-montserrat-regular text-[14px]">
              <hr className="border-fourth mb-5" />

              <p className="uppercase font-proximanova-alt-bold tracking-[0.3em]	rounded-xl bg-[#c0d34989] px-3 py-2 mb-4 w-fit">
                Explication
              </p>

              <p
                className="font-proximanova-regular"
                style={{ whiteSpace: "pre-line" }}
              >
                {isAnswerDisplayed ? "" : question.description}
              </p>
            </div>
          )}

          {/* Bouton pour afficher la réponse, si déjà appuyer affiche la question suivante et si c'est la fin, le bouton terminé */}

          {isAnswerDisplayed ? (
            <div className="flex md:justify-end items-center justify-center">
              <SecondaryButton
                onClick={handleClickAnswer}
                className={joinClasses(
                  "absolute bottom-0 right-1/2 md:right-14 translate-y-1/2 translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal",

                  !isAwnserd &&
                    "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                )}
              >
                Vérifier {">"}
              </SecondaryButton>
            </div>
          ) : isAwnserd && index + 1 < questions.length ? (
            <div className="flex md:justify-end items-center justify-center">
              <SecondaryButton
                onClick={handleClick}
                className={joinClasses(
                  "sm:translate-y-1/2 sm:translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal mt-4",

                  !isAwnserd &&
                    "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                )}
              >
                Question suivante {">"}
              </SecondaryButton>
            </div>
          ) : (
            isAwnserd &&
            index + 1 === questions.length && (
              <div className="flex justify-end items-center">
                <PrimaryButton
                  type="submit"
                  className={joinClasses(
                    "translate-y-1/2 translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal",

                    !isAwnserd &&
                      "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                  )}
                >
                  Terminer
                </PrimaryButton>
              </div>
            )
          )}
        </QuestionTemplate>
      ))}
    </div>
  );
}
